import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Bienvenue à Serenity Escape Resort
			</title>
			<meta name={"description"} content={"Là où la détente rencontre l'élégance - Serenity Escape, votre sanctuaire de tranquillité\n"} />
			<meta property={"og:title"} content={"Bienvenue à Serenity Escape Resort"} />
			<meta property={"og:description"} content={"Là où la détente rencontre l'élégance - Serenity Escape, votre sanctuaire de tranquillité\n"} />
			<meta property={"og:image"} content={"https://brittanybeaches.live/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://brittanybeaches.live/images/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brittanybeaches.live/images/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brittanybeaches.live/images/images.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--dark"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contacter{" "}
					<br />
					Serenity Escape{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--dark" text-align="center">
					Pour découvrir la gamme complète de nos services ou pour réserver votre escapade sereine, veuillez contacter notre personnel accueillant. Nous nous engageons à faire en sorte que votre séjour soit l'incarnation de la détente et du luxe sur mesure.
				</Text>
				<Link
					href="mailto:blank?contact@brittanybeaches.live"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					contact@brittanybeaches.live
				</Link>
				<Link
					href="tel:+33 6 58 09 75 88"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+33 6 58 09 75 88
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--dark" text-align="center">
					48 Rte Neuve du Col-de-la-Luere, 69290
					<br />
					{" "}Grézieu-la-Varenne, France
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});